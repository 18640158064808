/* eslint-disable */
function getSystemLang() {
  let navLang = localStorage.getItem('lang');
  if(navLang==null){
    navLang = navigator.language.substring(0, 2);
  }
  var systemlang;
  if (navLang == 'zh'||navLang == 'cn') {
    systemlang = 'cn';
  } else {
    systemlang = 'en';
  }
  return systemlang;
}
function getLangAll(systemlang) {
  var tt = {};
  for (var key in langarr) {
    tt[key] = langarr[key][systemlang];
  }
  return tt;
}
var langarr = {
//----------------界面显示文字 start------------------
'login':{'cn':'登录','en':'Login'} ,
'text_pl_country':{'cn':'国籍','en':'Country/Region'} ,
'text_player':{'cn':'球员','en':'Player'} ,
'text_news':{'cn':'新闻','en':'News'} ,
'text_ranking':{'cn':'排名','en':'Rank'} ,
'text_price':{'cn':'积分','en':'Points'} ,
'text_price_sum':{'cn':'总积分','en':'Points'} ,
'Numberofentries':{'cn':'参赛场','en':'Entered'} ,
'Quit':{'cn':'退出','en':'Quit'} ,
'Username':{'cn':'账号','en':'Username'} ,
'Inputyourusername':{'cn':'请输入账号','en':'Input your username'} ,
'Password':{'cn':'密码','en':'Password'} ,
'Inputyourpassword':{'cn':'请输入密码','en':'Input your password'} ,
'Home':{'cn':'首页','en':'Home'} ,
'TournamentsSchedule':{'cn':'赛程','en':'Schedule'} ,
'News':{'cn':'新闻','en':'News'} ,
'Live':{'cn':'直播','en':'Live'} ,
'Stats':{'cn':'技术统计','en':'Stats'} ,
'Leaderboard':{'cn':'排行榜','en':'Leaderboard'} ,
'Leaderboard1':{'cn':'成绩','en':'Leaderboard'} ,
'Leaderboard1s':{'cn':'比赛成绩','en':'Results'} ,
'PointsRankings':{'cn':'排行榜','en':'Rankings'} ,
'RealtimeScore':{'cn':'即时成绩','en':'Live'} ,
'OrderofMerit':{'cn':'女子中巡奖金榜','en':'Order of Merit'} ,
'':{'cn':'新闻精选','en':'Selected News'} ,
'More':{'cn':'查看更多','en':'More'} ,
'':{'cn':'巡回赛合作伙伴','en':"Tour's Partner"} ,
'Enterprise':{'cn':'企业介绍','en':'About'} ,
'':{'cn':'巡回赛供应商','en':"Tour's Supplier"} ,
'Field':{'cn':'参赛球员','en':'Field'} ,
'':{'cn':'请输入球员名称','en':"Please Input Player's Name"} ,
'Inquiry':{'cn':'查询','en':'Inquiry'} ,
'Age':{'cn':'年纪','en':'Age'} ,
'Height':{'cn':'身高','en':'Height'} ,
'YearofTurningProfessional':{'cn':'转职时间','en':'Year of Turning Professional'} ,
'BestAwards':{'cn':'最好成绩','en':'Best Awards'} ,
'Yards/Pars':{'cn':'比赛码数/标准杆','en':'Yards/Pars'} ,
'':{'cn':'比赛形式','en':'Format'} ,
'':{'cn':'门票信息','en':'Tickets Info'} ,
'':{'cn':'赛事历史','en':'History'} ,
'':{'cn':'往年冠军','en':'Previous Champions'} ,
'Year':{'cn':'年份','en':'Year'} ,
'Champion':{'cn':'冠军球员','en':'Champion'} ,
'RoundScore':{'cn':'每轮成绩','en':'Round Score'} ,
'TotalScore':{'cn':'总杆数','en':'Total Score'} ,
'Events':{'cn':'赛事','en':'Events'} ,
'Period':{'cn':'时间','en':'Period'} ,
'WinstoPar':{'cn':'夺冠成绩','en':'Wins to Par'} ,
'':{'cn':'巡回赛新闻','en':'Tour News'} ,
'':{'cn':'头条新闻','en':'Highlights'} ,
'Order':{'cn':'序号','en':'Order'} ,
'text_Order':{'cn':'排序','en':'Order'} , 
'text_Order_add':{'cn':'按添加顺序','en':'In the order of addition'} , 
'text_Order_back':{'cn':'候补名单','en':'Waiting list'} ,
'text_Order_back_en':{'en':'候补名单','cn':'Waiting list'} ,
'text_PEPEO':{'cn':'人','en':''} ,
'SignUp':{'cn':'参赛报名','en':'Sign Up'} ,
'FactSheet':{'cn':'赛事信息','en':'Fact Sheet'} ,
'ydbxz':{'cn':'预定表下载','en':'Reservation table download'} ,
'none':{'cn':'无信息','en':'None'} ,
'ShGk':{'cn':'赛事概况','en':'Event Overview'} ,
'FactSheet1':{'cn':'赛事信息','en':'Tour Info'} ,
'FactSheet2':{'cn':'报名赛站','en':'Sign Up Event'} ,
'FactSheet3':{'cn':'报名信息','en':'Sign Up'} ,
'PleaseSelect':{'cn':'请选择','en':'Please Select'} ,
'SignedUpEvents':{'cn':'报名类型','en':'Sign Up Type'} ,
'SignUp':{'cn':'正赛报名','en':'Sign Up'} ,
'Time':{'cn':'赛事时间','en':'Time'} ,
'Venue':{'cn':'赛事地点','en':'Venue'} ,
'EntryFee':{'cn':'参  赛  费','en':'Entry Fee'} ,
'EntryFee1':{'cn':'正赛参赛费','en':'Entry Fee'} ,
'EntryDeadline':{'cn':'报名截止','en':'Entry Deadline'} ,
'ChineseName':{'cn':'中  文  名','en':'Chinese Name'} ,
'EnglishName':{'cn':'英  文  名','en':'English Name'} ,
'Nationality':{'cn':'所属国籍','en':'Nationality'} ,
'IDNumber':{'cn':'身份证号','en':'ID Number'} ,
'MobileNumber':{'cn':'手机号码','en':'Mobile Number'} ,
'EmailAddress':{'cn':'电子邮箱','en':'Email Address'} ,
'Identity':{'cn':'身       份','en':'Identity'} ,
'Attribution':{'cn':'代表球会/企业','en':'Attribution'} ,
'Bank':{'cn':'银       行','en':'Bank'} ,
'NameofAccountBank':{'cn':'开户行名称','en':'Name of Account Bank'} ,
'BankAccountNumber':{'cn':'银行账号','en':'Bank Account Number'} ,
'InvoiceTitle':{'cn':'发票名头','en':'Invoice Title'} ,
'TaxIdentificationNumber':{'cn':'税       号','en':'Tax Identification Number'} ,
'CGAMembershipNumber':{'cn':'中高协会员','en':'CGA Membership Number'} ,
'RelatedService':{'cn':'相关服务','en':'Related Service'} ,
'Hotel':{'cn':'酒        店','en':'Hotel'} ,
'OfficialHotel':{'cn':'入住官方酒店','en':'Official Hotel'} ,
'OfficialHotel1':{'cn':'官方酒店','en':'Official Hotel'} ,
'SelfArrangement':{'cn':'自己安排','en':'Self Arrangement'} ,
'Caddie':{'cn':'球        童','en':'Caddie'} ,
'CourseProvided':{'cn':'球会安排','en':'Club Caddie'} ,
'SelfProvided':{'cn':'自带球童','en':'Own Caddie'} ,
'Submit':{'cn':'提交','en':'Submit'} ,
'Theorderhasbeensubmitted':{'cn':'您已经提交订单','en':'The order submitted'} ,
'':{'cn':'参赛报名名单','en':'Field List'} ,
'AllOrders':{'cn':'全部订单','en':'All Orders'} ,
'WaitingforApproval':{'cn':'等待审核','en':'Waiting for Approval'} ,
'WaitingforPayment':{'cn':'等待支付','en':'Waiting for Payment'} ,
'Completed':{'cn':'已完成','en':'Completed'} ,
'Attention':{'cn':'特别提醒','en':'Attention'} ,
'OrderNumber':{'cn':'订单号','en':'Order Number'} ,
'EventName':{'cn':'赛事名称','en':'Tour'} ,
'EventNameService':{'cn':'收费项目','en':'Pay Service'} ,
'AmountYuan':{'cn':'金额(元)','en':'Amount (Yuan)'} ,
'StatusofOrder':{'cn':'订单状态','en':'Status of Order'} ,
'EventPeriod':{'cn':'比赛时间','en':'Event Period'} ,
'text_Reports':{'cn':'报告数量','en':'Reports'},
'text_StrokeGained':{'cn':'SG 报告','en':'SG'},
'DeadlinePaymentDate':{'cn':'最晚支付时间','en':'Deadline'} ,
'OrderTime':{'cn':'订单时间','en':'Order Time'} ,
'Operation':{'cn':'操作','en':'Operation'} ,
'PrintOrder':{'cn':'打印订单','en':'Print Order'} ,
'':{'cn':'订单下载打印','en':'Order Download and Print'} ,
'':{'cn':'下载中.....','en':'Downloading…'} ,
'RefundConfirm':{'cn':'确认需要退款操作','en':'Refund Confirm'} ,
'OperationConfirm':{'cn':'操作确认','en':'Operation Confirm'} ,
'Confirm':{'cn':'确定','en':'Confirm'} ,
'Cancel':{'cn':'取消','en':'Cancel'} ,
'':{'cn':'提交成功','en':'Submittted Successfully'} ,
'':{'cn':'微信扫码付款','en':'Wechat QR Code Scan Payment'} ,
'PaidSuccessfully':{'cn':'支付成功','en':'Paid Successfully '} ,
'SelecttheMethodofPayment':{'cn':'请选择支付方式','en':'Select the Method of Payment'} ,
'':{'cn':'微信','en':'Wechat'} ,
'':{'cn':'支付宝','en':'Alipay'} ,
'PickupDropoffService':{'cn':'接送机预定','en':'Pick-up'} ,
'PickupService':{'cn':'接机预定','en':'Pick-up Service'} ,
'DropoffService':{'cn':'送机预定','en':'Drop-off Service'} ,
'NameoftheEntriedEvent':{'cn':'报名赛事名称','en':'Name of the Entried Event'} ,
'EventPeriod':{'cn':'比赛时间','en':'Date'} ,
'NoticeTime':{'cn':'通知时间','en':'Notice Time'} ,
'NoticeTime_info':{'cn':'通知内容','en':'Notice'} ,
'PickupInfo':{'cn':'接机信息填写','en':'Pick-up Info'} ,
'DropoffInfo':{'cn':'送机机信息填写','en':'Drop-off Info'} ,
'NoneedforPickup':{'cn':'无需接机','en':'No need for Pick-up'} ,
'NoneedforDropoff':{'cn':'无需送机','en':'No need for Drop-off'} ,
'CourseIntroduction':{'cn':'球场介绍','en':'Course'} ,
'CourseName':{'cn':'球场名称','en':'Course Name'} ,
'CourseLocation':{'cn':'球场地点','en':'Course Location'} ,
'TotalLength':{'cn':'球场总长度','en':'Total Length'} ,
'CourseWebsiteLink':{'cn':'球场官网友情链接','en':'Course Website Link'} ,
'':{'cn':'码','en':'Yard'} ,
'':{'cn':'球场整体地图','en':'Course Layout'} ,
'Hole':{'cn':'洞号','en':'Hole'} ,
'Yards':{'cn':'码数','en':'Yards'} ,
'Description':{'cn':'文字描述','en':'Description'} ,
'Pictures':{'cn':'图片展示','en':'Pictures'} ,
'TeeTimes':{'cn':'分组表','en':'Tee Times'} ,
'SubjecttotheLocalTimeZone':{'cn':'当地时区','en':'Local Time Zone'} ,
'Gruop':{'cn':'组','en':'Group'} ,
'Start':{'cn':'开球时间','en':'Start'} ,
'Tee':{'cn':'发球台','en':'Tee'} ,
'':{'cn':'球员成绩表','en':'Results'} ,
'Amateur':{'cn':'(A) 业余','en':'(A) Amateur'} ,
'StartfromBacknine':{'cn':'*  后九洞开球','en':'* Start from Back 9'} ,
'Confirmed':{'cn':'成绩已确认','en':'Confirmed'} ,
'Unconfirmed':{'cn':'选成绩未确认','en':'Unconfirmed'} ,
'WoninPlayoff':{'cn':'延长赛获胜','en':'Won in Playoff'} ,
'DownloadPDF':{'cn':'下载','en':'Download'} ,
'txt_DownloadPDF':{'cn':'下载PDF报告','en':'Download'} ,
'DownloadExcel':{'cn':'下载','en':'Download'} ,
'Today':{'cn':'本轮','en':'Today'} ,
'Today1':{'cn':'本轮','en':'Today'} ,
'Score':{'cn':'杆数','en':'Score'} ,
'Putts':{'cn':'推杆','en':'Putts'} ,
'Drive':{'cn':'发球','en':'Drive'} ,
'Accuracy':{'cn':'准确率','en':'Accuracy'} ,
'Bunker':{'cn':'沙坑','en':'Bunker'} ,
'Save':{'cn':'救球率','en':'Saves'} ,
'Average':{'cn':'平均','en':'Average'} ,
'DrivingDistance':{'cn':'开球距离','en':'Driving Distance'} ,
'Par':{'cn':'标准杆','en':'G'} ,
'GIR':{'cn':'上果岭率','en':'GIR'} ,
'PerRound':{'cn':'每轮','en':'Per Round'} ,
'OnePutt':{'cn':'一推杆','en':'One-Putt'} ,
'GIR1':{'cn':'标准杆上果岭','en':'GIR'} ,
'AveragePutting':{'cn':'平均推杆','en':'Average Putting'} ,
'Scrambling':{'cn':'未标准杆上果岭','en':'Scrambling'} ,
'Save1':{'cn':'救球率','en':'Saves'} ,
'':{'cn':'本轮杆数','en':'Round Score'} ,
'':{'cn':'本轮推杆','en':'Round Putts'} ,
'':{'cn':'开球准确率','en':'Driving Accuracy'} ,
'':{'cn':'沙坑救球率','en':'Sand Save'} ,
'':{'cn':'平均开球距离','en':'Average Driving Distance'} ,
'':{'cn':'标准杆上果岭率','en':'Green in Regulation(GIR)'} ,
'':{'cn':'每轮一推杆','en':'One-Putt Per Round'} ,
'':{'cn':'标准杆上果岭平均推杆','en':'Putts Per GIR'} ,
'':{'cn':'未标准杆上果岭救球率','en':'Scrambling'} ,
'EntryOrder':{'cn':'报名订单','en':'Entry Order'} ,
'OtherOrder':{'cn':'其他订单','en':'Other Order'} ,
'Pickupservice':{'cn':'接送机','en':'Pick-up service'} ,
'Pickupservicetobeconfirmed':{'cn':'接送机信息待更新','en':'Pick-up service to be confirmed'} ,
'TotalPurse':{'cn':'赛事总奖金','en':'Total Purse'} ,
'WatchLiveStream':{'cn':'直播中','en':'LIVE'} ,
'TourInfo':{'cn':'巡回赛信息','en':'Tour Info'} ,
'Sh':{'cn':'赛事','en':'Match'} ,
'Score':{'cn':'成绩','en':'Score'} ,
'News':{'cn':'新闻','en':'News'} ,
'Pictures':{'cn':'图片','en':'Pictures'} ,
'Video':{'cn':'视频','en':'Video'} ,
'':{'cn':'精彩新闻','en':'News Highlights'} ,
'PleaseSelecttheYear':{'cn':'请选择年份','en':'Please Select the Year'} ,
'PleaseSelecttheEvent':{'cn':'请选择赛事','en':'Please Select the Event'} ,
'PleaseInput':{'cn':'请输入搜索内容','en':"Search Player"},
'PleaseInput1':{'cn':'请输入搜索内容','en':"Please enter search content"},
'':{'cn':'头条新闻','en':'Headlines'} ,
'':{'cn':'巡回赛新闻','en':'Tour News'} ,
'':{'cn':'赛站新闻','en':'Event News'},
'Rounds1':{'cn':'参赛','en':'Events'} ,
'SGTotal':{'cn':'综合','en':'SG Total'} ,
'':{'cn':'女子中巡奖金榜','en':'Order of Merit'} ,
'Rounds':{'cn':'晋级','en':'Cuts Maded'} ,
'':{'cn':'劳力士世界排名','en':"Rolex Women's World Golf Rankings"} ,
'':{'cn':'中国女子高尔夫运动员排名','en':"CGA Women's Golf Rankings"} ,
'ThePrevious':{'cn':'上一场','en':'The Previous'} ,
'Ongoing':{'cn':'进行中','en':'Ongoing…'} ,
'TheNext':{'cn':'下一场','en':'The Next'} ,
'EventNameLocation':{'cn':'赛事名称/赛事地点','en':'Event Name/Location'} ,
'DefendingChampionPrize1':{'cn':'卫冕冠军/冠军','en':'Defending Champion/Champion'} ,
'DefendingChampionPrize2':{'cn':'赛事总奖金','en':'Prize'} ,
'':{'cn':'已完成赛事','en':'Events Completed '} ,
'text_ren':{'cn':'人','en':''} ,
'Averageintegral':{'cn':'平均积分','en':'Average'} ,
'text_di':{'cn':'第','en':'Round'} ,
'text_lun':{'cn':'轮','en':''} ,
'Applyforrefund':{'cn':'申请退款','en':'Apply for refund'} ,
'resstpassword':{'cn':'忘记密码','en':'Forgot Password'} ,
'enteraccount':{'cn':'请先输入账号！','en':'Please enter the account number first!'},
"ts1":{'cn':'密码错误，是否需要重置','en':'Password is wrong;Do you want to reset the password?'},
"ts2":{'cn':'新密码已发送至','en':'New password has been sent to '},
"ts3":{'cn':'邮箱','en':''},
"ts4":{'cn':'您的邮箱不存在,请联系管理员','en':'Your mailbox does not exist. Please contact the administrator'},
"ts5":{'cn':'发送失败','en':'Fail in send'},
"ts6":{'cn':"系统将重置密码，发送至您的邮箱",'en':'Do you want to send the new password to '},
"ts7":{'cn':"，确定重置吗？",'en':'?'},
'text_ChangePassword':{'cn':'修改密码','en':'Change Password'} ,
'text_Newpassword':{'cn':'输入新密码','en':'New password'} ,
'text_Newpasswordagain':{'cn':'再次输入新密码','en':'New password again'} ,
'text_signup':{'cn':'报名','en':'sign up'} ,
'oPayment':{'cn':'支付','en':'Pay'} ,
'oCancel':{'cn':'取消订单','en':'cancel'} ,
'text_Signupinfo':{'cn':'报名信息','en':'Signup info'} ,
'text_Playersname':{'cn':'球员姓名','en':"Player's name"} ,
'text_IDtype':{'cn':'证件类型','en':'ID type'} ,
'text_IDnumber':{'cn':'证件号码','en':'ID number'} ,
'text_Gender':{'cn':'性别','en':'Gender'} ,
'text_Pro':{'cn':'职业','en':'Pro'} ,
'text_Am':{'cn':'业余','en':'Am'} ,
'text_Nationality':{'cn':'国家/地区','en':'Country/Region'} ,
'text_Birthdate':{'cn':'出生日期','en':'Birth date'} ,
'text_date':{'cn':'日期','en':'Date'} ,
'text_Selectdate':{'cn':'选择日期','en':'Select date'} ,
'text_Relationtype':{'cn':'与被监护人关系','en':'Relation type'} ,
'text_Guardiansname':{'cn':'监护人姓名','en':"Guardian's name"} ,
'text_Contactinfo':{'cn':'监护人联系方式','en':'Contact info'} ,
'text_Countrycode':{'cn':'国际区号','en':'Country code'} ,
'text_IDofWorldWomensgolf':{'cn':'世界女子高尔夫排名ID','en':"ID of World Women's golf rankings"} ,
'text_Airportpickupinfo':{'cn':'接机信息','en':'Airport pickup info'} ,
'text_Airportdropoffinfo':{'cn':'送机信息','en':'Airport drop-off info'} ,
'text_Noticearrangementsforpickup':{'cn':'通知：接送机安排将于近期公布，望周知。','en':'Notice: arrangements for pick-up and drop-off will be announced in the near future.'} ,
'text_Ihavereadtheevent':{'cn':'我已阅读赛事相关信息并清楚报名费支付成功后不予退还','en':' I have read the event information and know that the entry fee is non-refundable after successful payment'} ,
'text_Submitandpayforthesignup':{'cn':'提交并支付报名','en':'Submit'} ,
'text_Signup':{'cn':'参赛报名','en':'Sign up'} ,
'text_Signupinfo1':{'cn':'报名查询','en':'Signup info'} ,
'WaitingforPaymentN':{'cn':'提交支付','en':'Pay'} ,
'text_Submit':{'cn':'确认查询','en':'Submit'} ,
'text_back':{'cn':'返回','en':'Back'},
'WaitingforPaymentN':{'cn':'提交支付','en':'Pay'},
'notsame':{'cn':'两次输入的密码不相同','en':'passwords different'},
'nve':{'cn':'女','en':'girl'},
'look':{'cn':'查看','en':'Details'},
'PersonalScore':{'cn':'个人成绩','en':'Personal Score'},
'TeamScore':{'cn':'团队成绩','en':'Team Score'},
'text_IDtype_select':{'cn':'请选择证件类型','en':'Please select a certificate type'} ,
'cardpayTs':{'cn':'等待付款，请勿关闭此页面，关闭后无法接收支付状态','en':'Please do not close this page while waiting for payment. Payment status cannot be accepted after closing'},

'MatchTsnr_c':{'cn':'没有进行中比赛','en':'Unprocessed Competitions'},
'MatchTsnr_o':{'cn':'暂无已完结的比赛','en':'No Completed Matches'},
'MatchTsnr_f':{'cn':'暂无未来比赛','en':'No Future Competitions'},
'cj_zd':{'cn':'逐洞成绩','en':'Hole by hole'},
'cj_lxb':{'cn':'领先榜','en':'Leaderboard'},
'tm_cj_zd':{'cn':'团队逐洞','en':'Team Hole by hole'},
'tm_cj_lxb':{'cn':'团队领先','en':'Team Leaderboard'},
'cj_tm':{'cn':'团队成绩','en':'Team Score'},
'tj_date':{'cn':'统计至','en':'RANKINGS AS OF'},
'zdqq2':{'cn':'逐洞取前2','en':'Best 2 scores each hole'},
'yywjj':{'cn':'业余无奖金','en':'No prize money for amateur'},

//playerIfno
'p_age':{'cn':'年龄','en':'Age'},
'p_joinclpga':{'cn':'加入女子中巡：','en':'Year Joined：'},
'p_clpgapm':{'cn':'女子中巡排名','en':'Rank(CLPGA)'},
'p_shijipm':{'cn':'世界排名','en':' Rank(ROLEX)'},
'p_zhmc':{'cn':'最好名次','en':'Best Pos'},
'p_gjcs':{'cn':'冠军次数','en':'Wins'},

'p_career':{'cn':'生涯','en':'Career'},
'p_score':{'cn':'成绩','en':'Results'},
'p_sta':{'cn':'统计','en':'Stats'},
'p_new':{'cn':'新闻','en':'News'},
'p_video':{'cn':'视频','en':'Video'},
'p_player':{'cn':'球员','en':'Player'},
'p_player_en':{'cn':'Player','en':'球员'},
'p_matchname':{'cn':'比赛名称','en':'Tour'},
'p_paiming':{'cn':'排名','en':'Rank'},
'p_paiming1':{'cn':'排名','en':'Pos'},
'p_prize':{'cn':'奖金','en':'Prize'},
'p_allscore':{'cn':'总杆','en':'Total'},
'p_kqjl':{'cn':'开球距离(yds)','en':'Driving Distance(yds)'},
'p_sqdv':{'cn':'上球道率(%)','en':'Driving Accuracy(%)'},
'p_sglv':{'cn':'上果岭率(%)','en':'Green in REG(%)'},
'p_wdbsglv':{'cn':'未标上果岭救球率(%)','en':'Scrambling(%)'},
'p_bsllpjt':{'cn':'标上果岭平均推杆','en':'Putts per GIR'},
'p_skjql':{'cn':'沙坑救球率(%)','en':'Bunker Saves(%)'},

'psta_years':{'cn':'年份','en':'Year'},
'psta_match_count':{'cn':'参赛场次','en':'Played'},
'psta_match_supper_rank':{'cn':'最好名次','en':'Best Pos'},
'psta_match_join_count':{'cn':'晋级次数','en':'Cuts Maded'},
'psta_match_rank_10':{'cn':'前十次数','en':'Top 10'},
'psta_match_rank_3':{'cn':'前三次数','en':'Top 3'},
'psta_match_rank_1':{'cn':'冠军次数','en':'Wins'},
'psta_match_prize':{'cn':'获得奖金','en':'Official Prize'},
'psta_match_clpa_rank':{'cn':'女子中巡排名','en':'Rank'},
'psta_more':{'cn':'更多','en':'More'},

'psta_xm':{'cn':'统计项目','en':'Item'},
'psta_data':{'cn':'数 据','en':'Value'},
'psta_rank':{'cn':'排 名','en':'Rank'},

'psta_dldybzg':{'cn':'单轮低于标杆','en':'Under Par'},
'psta_ci':{'cn':'(次)','en':'(times)'},

'psta_pjkqjl':{'cn':'平均开球距离','en':'Driving Distance'},
'psta_pingjunganshu':{'cn':'平均杆数','en':'Stroke AVG'},
'psta_pjtuigan':{'cn':'平均推杆','en':'Putts per Round'},
'psta_laoyinghsu':{'cn':'老鹰数','en':'Eagle'},
'psta_xiaoniaoshu':{'cn':'小鸟数','en':'Birdie'},
'psta_bianzhunganshu':{'cn':'标准杆数','en':'Par'},
'psta_yganjindong':{'cn':'一杆进洞','en':'Hole in one'},
'psta_sqdlv':{'cn':'上球道率','en':'Driving Accuracy'},
'psta_sglv':{'cn':'上果岭率','en':'Green in REG'},
'psta_skjql':{'cn':'沙坑救球率','en':'Bunker Saves'},
'psta_wbsglinglve':{'cn':'未标上果岭救球率','en':'Scrambling'},
'psta_bgglpjtg':{'cn':'标上果岭平均推杆','en':'Putts per GIR'},
'psta_pjyts':{'cn':'平均一推数','en':'One Putt'},
'psta_zj':{'cn':'总计','en':'总计'},

'psearch_search':{'cn':'查找','en':'Search'},
'psearch_seaz':{'cn':'筛选 A~Z','en':'Option A~Z'},
'psearch_contentdesc':{'cn':'可输入 球员全部/部分中文或英文名字、世界积分ID、国家/地区的中/英文','en':'Searching by player name、WWGR ID、Country or region'},
'psearch_more':{'cn':'点击加载更多','en':'View More'},

'matchyf_1':{'cn':'1','en':'Jan'},
'matchyf_2':{'cn':'2','en':'Feb'},
'matchyf_3':{'cn':'3','en':'Mar'},
'matchyf_4':{'cn':'4','en':'Apr'},
'matchyf_5':{'cn':'5','en':'May'},
'matchyf_6':{'cn':'6','en':'Jun'},
'matchyf_7':{'cn':'7','en':'Jul'},
'matchyf_8':{'cn':'8','en':'Aug'},
'matchyf_9':{'cn':'9','en':'Sep'},
'matchyf_10':{'cn':'10','en':'Oct'},
'matchyf_11':{'cn':'11','en':'Nov'},
'matchyf_12':{'cn':'12','en':'Dec'},
'matchyf_matchallprice':{'cn':'赛事总奖金：','en':'PURSE：'},
'matchyf_gj':{'cn':'卫冕冠军：','en':'WINNER：'},
'matchyf_gj1':{'cn':'冠军：','en':'WINNER：'},
'matchyf_dgcj':{'cn':'夺冠成绩：','en':'SCORE：'},
'matchyf_year':{'cn':'年度','en':''},
'matchyf_year1':{'cn':'','en':''},
'matchyf_year3':{'cn':'年度','en':'Season'},
'matchyf_sphm':{'cn':'视频画面','en':'VIDEO'},
'matchyf_hmk':{'cn':'横屏看','en':''},
'matchyf_zdcj':{'cn':'逐洞成绩','en':'Scorecard'},
'matchyf_qh':{'cn':'切回','en':'Back to'},
'matchyf_lxb':{'cn':'领先榜','en':'LB'},
'matchyf_guanzhuqiuyuan':{'cn':'关注的球员','en':'Favorites'},
'matchyf_player1':{'cn':'球员1','en':'Player1'},
'matchyf_player2':{'cn':'球员2','en':'Player2'},
'matchyf_player3':{'cn':'球员3','en':'Player3'},
'matchyf_player4':{'cn':'球员4','en':'Player4'},




'tj_date':{'cn':'更新日期：','en':'Updated:'},
'Players & Stats':{'cn':'球员/数据','en':'Players & Stats'},
'Players & Stats_en':{'cn':'Players & Stats','en':'球员/数据'},
'ROLEX RANKINGS':{'cn':'劳力士世界排名','en':'ROLEX RANKINGS'},
'ROLEX RANKINGS_en':{'cn':'ROLEX RANKINGS','en':'劳力士世界排名'},
'Order of Merit':{'cn':'女子中巡排名','en':'CLPGA RANKINGS'},
'Order of Merit_en':{'cn':'CLPGA RANKINGS','en':'女子中巡排名'},
'CGA RANKINGS':{'cn':'中高协排名','en':'CGA RANKINGS'},
'CGA RANKINGS_en':{'cn':'CGA RANKINGS','en':'中高协排名'},
'EXEMPT ORDER':{'cn':'资格排序','en':'EXEMPT ORDER'},
'EXEMPT ORDER_en':{'cn':'EXEMPT ORDER','en':'资格排序'},
'times':{'cn':'次数','en':'Times'},
'cansaichangci':{'cn':'参赛场次','en':'Events'},
'jinjichangci':{'cn':'晋级场次','en':'Cuts Maded'},
'pingjunganshu':{'cn':'平均杆数','en':'Stroke AVG'},
'top10num':{'cn':'前十次数','en':'Top 10'},
'duoguancishu':{'cn':'夺冠次数','en':'Wins'},
'chakangengduo':{'cn':'查看更多','en':'All'},
'gotovisit':{'cn':'数据由中高协提供，点击查看更多前往中高协官网','en':'Supplied by CGA, go to visit now'},
'chakanquanbu':{'cn':'查看全部','en':'All'},
'shangzhoupaiming':{'cn':'上周排名','en':'Last week'},
'niandu':{'cn':'年度','en':'Season'},
'qianncishu_qian':{'cn':'前','en':'TOP'},
'qianncishu_cishu':{'cn':'次数','en':''},
'qianncishu_qian_en':{'cn':'TOP','en':'前'},
'qianncishu_cishu_en':{'cn':'','en':'次数'},
'quanbuniandu':{'cn':'全部年度','en':'All'},
'quanbu':{'cn':'全部','en':'All'},
'year_cn':{'cn':'年度','en':''},
'year_en':{'cn':'','en':'Season '},
'chang':{'cn':'场','en':''},
'ci':{'cn':'次','en':''},
'paiming':{'cn':'排名','en':'Rank'},
'pingjunjifen':{'cn':'平均积分','en':'AVG PTS'},

'AboutCLPGA':{'cn':'关于CLPGA','en':'About CLPGA'},
'CreatingValuescn':{'cn':'为女子职业球员创造价值','en':'Creating Values for Women Professional Golfers'},
'CreatingValuesen':{'cn':'Creating Values for Women Professional Golfers','en':'为女子职业球员创造价值'},
'Beijing':{'cn':'北京','en':'Beijing'},
'textTEL':{'cn':'电话','en':'TEL'},
'textFAX':{'cn':'传真','en':'FAX'},
'Xiamen':{'cn':'厦门','en':'Xiamen'},
'Email':{'cn':'垂询邮箱','en':'Email'},
'Marketing':{'cn':'商务咨询','en':'Marketing'},
'CLPGABeijing':{'cn':'中女职业高尔夫赛事(北京)有限公司','en':'CLPGA (Beijing) Co., Ltd.'},
'text99_Sponsors_cn':{'cn':'赛事赞助商','en':'Sponsors'},
'text99_Sponsors_en':{'cn':'Sponsors','en':'赛事赞助商'},
'text99_TourPartners_cn':{'cn':'巡回赛合作伙伴','en':'Tour Partners'},
'text99_TourPartners_en':{'cn':'Tour Partners','en':'巡回赛合作伙伴'},
'text99_TourSuppliers_cn':{'cn':'巡回赛赞助商','en':'Tour Sponsors'},
'text99_TourSuppliers_en':{'cn':'Tour Sponsors','en':'巡回赛赞助商'},
'text99_AboutChinaLPGA_cn':{'cn':'关于CLPGA','en':'About China LPGA Tour'},
'text99_AboutChinaLPGA_en':{'cn':'About China LPGA Tour','en':'关于CLPGA'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'text99_Email':{'cn':'垂询邮箱','en':'Email'},
'newslist_news':{'cn':'新闻','en':'News'},
'newslist_news_en':{'cn':'News','en':'新闻'},
'newslist_quanbu':{'cn':'全部新闻','en':'All'},
'newslist_zuixin':{'cn':'最近新闻','en':'Lastest'},
'newslist_pinpai':{'cn':'品牌故事','en':'Brand'},
'newslist_qiuyuan':{'cn':'球员故事','en':'Player'},
'newslist_guoji':{'cn':'国际故事','en':'International'},
'newslist_zhuangbei':{'cn':'装备','en':'Equipment'},
'newslist_jiaoxue':{'cn':'教学','en':'Teaching'},
'csqy_csqj':{'cn':'参赛球员','en':'Entry List'},
'csqy_csqj_en':{'cn':'Entry List','en':'参赛球员'},
'jstj_jstj':{'cn':'技术统计','en':'Statistics'},
'jstj_jstj_en':{'cn':'Statistics','en':'技术统计'},
'qcjs_qcgl':{'cn':'球场概览','en':'Overview'},
'qcjs_qcgl_en':{'cn':'Overview','en':'球场概览'},
'qcjs_dbwz':{'cn':'洞杯位置','en':'Hole Locations'},
'qcjs_dbwz_en':{'cn':'Hole Locations','en':'洞杯位置'},
'qcjs_di':{'cn':'第','en':'Round '},
'qcjs_lun':{'cn':'轮','en':''},
// 截止目前108人参赛
'csqy_jzmq':{'cn':'截止目前','en':''},
'csqy_cs':{'cn':'参赛','en':'players'},
'csqy_cs_back':{'cn':'后补','en':'players'},
'csqy_zjycxg':{'cn':'最近一次更新：','en':'Updated：'},
'csqy_czqy':{'cn':'输入球员全部/部分名字 或世界积分ID查找','en':'Searching by player\'s name、WWGR ID'},
'qcjs_qcmc':{'cn':'球场名称','en':'Course'},
'qcjs_bzg':{'cn':'标准杆','en':'Par'},
'qcjs_zcd':{'cn':'总长度','en':'Yardage'},
'qcjs_qcdd':{'cn':'球场地点','en':'Location'},
'qcjs_qcgw':{'cn':'球场官网','en':'Web'},
'qcjs_sz':{'cn':['〇', '一', '二', '三', '四', '五', '六', '七'],'en':['0','1','2','3','4','5','6','7']},
'newslist_saizhanxinwen':{'cn':'赛站新闻','en':'Event News'},
'newslist_saizhanxinwen_en':{'cn':'Event News','en':'赛站新闻'},
'newslist_yues':{'cn':['','1','2','3','4','5','6','7','8','9','10','11','12'],'en':['','Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']},
'newslist_yue':{'cn':'月','en':''},
'newslist_jinxingzhongbisai':{'cn':'进行中比赛','en':'Current'},
'newslist_jieshubisai':{'cn':'结束的比赛','en':'Completed'},
'newslist_jijiangkaisai':{'cn':'即将开赛','en':'Upcoming'},
'newslist_guanjianci':{'cn':'关键词','en':'Keywords'},
'newslist_xinwensousuo':{'cn':'可输入 新闻标题、新闻内容、关键字、新闻标签进行搜索','en':'Searching by title,content,key words or tag'},
// '搜索到 {{search}} 相关内容 {{page.count}} 条',There are ({{page.count}) results related to “{{search}}”
'newslist_sousuojieguo':{'cn':'搜索到','en':'There are'},
'newslist_xiangguanneirong':{'cn':'相关内容','en':'related to'},
'newslist_tiao':{'cn':'条','en':'results'},
// 排名 球员 成绩 本轮 推杆 上球道率 沙坑救球率 发球距离 一推数  
// 上果岭率 杆上果岭平均推杆 未标上果岭救球率
'stats_Today':{'cn':'本轮','en':'Today'} ,
'stats_Score':{'cn':'','en':''} ,
'stats_Today1':{'cn':'推杆','en':'Putts'} ,
'stats_Putts':{'cn':'','en':''} ,
'stats_Drive':{'cn':'上球道','en':'Drive'} ,
'stats_Accuracy':{'cn':'率','en':'Accuracy'} ,
'stats_Bunker':{'cn':'沙坑','en':'Bunker'} ,
'stats_Save':{'cn':'救球率','en':'Saves'} ,
'stats_Average':{'cn':'发球','en':'Driving'} ,
'stats_DrivingDistance':{'cn':'距离','en':'Distance'} ,
'stats_Par':{'cn':'上果岭','en':'Green'} ,
'stats_GIR':{'cn':'率','en':'in REG'} ,
'stats_PerRound':{'cn':'一推数','en':'One'} ,
'stats_OnePutt':{'cn':'','en':'Putt'} ,
'stats_GIR1':{'cn':'标上果岭','en':'Putts'} ,
'stats_AveragePutting':{'cn':'平均推杆','en':'per GIR'} ,
'stats_Scrambling':{'cn':'未标上果岭','en':'Scrambling'} ,
'stats_Save1':{'cn':'救球率','en':''} ,
'stats_paiming':{'cn':'排名','en':'Pos'} ,
'stats_qiuyuan':{'cn':'球员','en':'Player'} ,
'stats_chengji':{'cn':'成绩','en':'Results'} ,

'singlematch_ssxw':{'cn':'赛事新闻','en':'News'} ,
'singlematch_group':{'cn':'分组','en':'Tee Times'} ,
'singlematch_news':{'cn':'新闻','en':'News'} ,
'singlematch_bm':{'cn':'报名','en':'Register'} ,
'singlematch_csmd':{'cn':'参赛球员','en':'Field'} ,
'xinwenjingxuan':{'cn':'新闻精选','en':'Selected News'} ,
'xinwenjingxuan_en':{'cn':'Selected News','en':'新闻精选'} ,
'xinwenjingxuan_down':{'cn':'下载中.....','en':'Downloading…'} ,
'chengji_tishi':{'cn':'请将手机切换至横屏查看','en':'Make your phone to Auto-rotate'} ,
'home_stats':{'cn':'统计数据','en':'Stats'} ,
'home_stats_en':{'cn':'Stats','en':'统计数据'} ,

'home_saishishijian':{'cn':'赛事时间','en':'赛事时间'} ,
'home_saishididian':{'cn':'赛事地点','en':'赛事地点'} ,
'home_saishizongjiangjin':{'cn':'赛事总奖金','en':'赛事总奖金'} ,
'home_jinxingzhong':{'cn':'进行中','en':'Current'} ,
'home_xiayichang':{'cn':'下一场','en':'Upcoming'} ,
'home_more':{'cn':'查看更多','en':'More'} ,
'home_paiming':{'cn':'排名','en':'Rank'} ,
'home_paiming_pos':{'cn':'排名','en':'Pos'} ,
'home_qiuyuan':{'cn':'球员','en':'Player'} ,
'home_chengji':{'cn':'成绩','en':'Topar'} ,
'home_wancheng':{'cn':'进度','en':'Thru'} ,
'home_shuju':{'cn':'数据','en':'Value'} ,
'wucansai':{'cn':'无参赛记录','en':'No Records'},
'fbrq':{'cn':'发表日期：','en':'Publication Date：'},
'user_ssxx':{'cn':'赛事信息','en':'Information'},
'user_tbtx':{'cn':'特别提醒','en':'Attention'},
'user_detail':{'cn':'查看详情','en':'Detail'},
'user_down':{'cn':'下载','en':'Down'},
'user_match_all':{'cn':'全部赛事','en':'All'},
'user_match_r':{'cn':'进行中','en':'Current'},
'user_match_c':{'cn':'已结束','en':'Completed'},
'user_jsj_time':{'cn':'预定时间','en':'Time'},
'user_jsj_match':{'cn':'预定赛事','en':'Tour'},
'user_jsj_amount':{'cn':'金额(元)','en':'Price(RMB)'},
'user_jsj_content':{'cn':'内容','en':'Content'},
'bminfosss':{'cn':'报名信息','en':'Registration information'},


'xhs_xhs':{'cn':'小红书','en':'Little Red Book'},
'xhs_wb':{'cn':'微博','en':'Weibo'},
'xhs_tik':{'cn':'抖音','en':'Tik Tok'},
'xhs_wx':{'cn':'视频号','en':'Weixin'},
'user_Clicktodownloadtheimage':{'cn':'点击此处下载图片','en':'Click to download the image,'},
'user_LongpresstoscaninWeChat':{'cn':'在微信内可长按识别','en':'Long-press to scan in WeChat.'},




};


// exports.getLang = getLang;
exports.getLangAll    = getLangAll;
exports.getSystemLang = getSystemLang;